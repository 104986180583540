var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BModal', {
    attrs: {
      "id": "modal-sold",
      "title": _vm.$t('modal.confirm'),
      "title-class": "text-airline font-weight-bolder ".concat(_vm.isMobileView ? 'font-medium-2' : 'font-medium-4'),
      "body-class": "p-1",
      "size": "lg",
      "centered": ""
    },
    on: {
      "ok": function ok($event) {
        return _vm.$emit('sold', _vm.item);
      }
    }
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t('modal.sold')) + " ")]), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "notePrivate"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('resellTicket.form.notePrivate')) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-form-textarea', {
    attrs: {
      "placeholder": _vm.$t('resellTicket.form.notePrivate'),
      "name": _vm.$t('resellTicket.form.notePrivate')
    },
    model: {
      value: _vm.item.notePrivate,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "notePrivate", $$v);
      },
      expression: "item.notePrivate"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }