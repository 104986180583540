<template>
  <BModal
    id="modal-sold"
    :title="$t('modal.confirm')"
    :title-class="`text-airline font-weight-bolder ${isMobileView ? 'font-medium-2' : 'font-medium-4'}`"
    body-class="p-1"
    size="lg"
    centered
    @ok="$emit('sold',item)"
  >
    <label>
      {{ $t('modal.sold') }}
    </label>
    <!-- ANCHOR notePrivate -->
    <b-col
      sm="12"
      class="mt-2"
    >
      <b-form-group label-for="notePrivate">
        <template #label>
          {{ $t('resellTicket.form.notePrivate') }}
        </template>
        <b-form-textarea
          v-model="item.notePrivate"
          :placeholder="$t('resellTicket.form.notePrivate')"
          :name="$t('resellTicket.form.notePrivate')"
        />
      </b-form-group>
    </b-col>
  </BModal>
</template>
<script>
import { ref, toRefs, watch } from '@vue/composition-api'
import {
  BCol, BFormGroup, BFormTextarea, BModal,
} from 'bootstrap-vue'

export default {
  components: {
    BModal, BFormGroup, BFormTextarea, BCol,
  },
  props: {
    resellTicket: {
      type: Object,
      default: () => ({ notePrivate: '' }),
    },
  },
  setup(props, { emit }) {
    const { resellTicket } = toRefs(props)
    const item = ref({ notePrivate: '' })
    watch(resellTicket, val => {
      item.value = val
    })

    return {
      item,
    }
  },
}
</script>
<style lang="">

</style>
